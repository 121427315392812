import React from 'react';
import {ReactComponent as Logo} from "../images/EdenGreenLogo_min.svg";
import {Link} from "react-router-dom";
import {ReactComponent as InstagramIcon} from "../images/instagram.svg";
import {ReactComponent as FacebookIcon} from "../images/facebook.svg";
import {ReactComponent as WhatsAppIcon} from "../images/whatsapp.svg";
import {HashLink} from "react-router-hash-link";

// const windowHeight = window.innerHeight

export const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -100;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
}

// const mobileNavs = {
//     open: {transition: {staggerChildren: 0.07, delayChildren: 0.4}},
//     closed: {transition: {staggerChildren: 0.05, staggerDirec
//     tion: -1}},
// }
// const menuItems = {
//     initial: {
//         y: 100
//     },
//     open: {
//         y: 0,
//         opacity: 1,
//         transition: {
//             y: {stiffness: 1000, velocity: -100}
//         }
//     },
//     closed: {
//         y: 50,
//         opacity: 0,
//         transition: {
//             y: {stiffness: 1000}
//         }
//     }
// };
// const sidebar = {
//     open: (height = 1000) => ({
//         clipPath: `circle(${height * 2 + 200}px at 96.6% 1.35rem)`,
//         transition: {
//             duration: 0.3,
//             type: "spring",
//             stiffness: 20,
//             restDelta: 2
//         }
//     }),
//     closed: {
//         clipPath: "circle(60px at 96.6% 1.35rem)",
//         transition: {
//             delay: 0.2,
//             type: "spring",
//             stiffness: 400,
//             damping: 40
//         }
//     }
// };


const Footer = (props) => {

    return (
        <div className="w-full px-8">
            <hr className="w-full h-2 mx-auto"/>
            <div className="grid grid-rows-1 md:grid-cols-5 gap-y-6 py-2">
                <div className=" text-grey flex md:flex-col space-x-5 md:space-x-0 order-5 md:order-1 items-center md:space-y-5">
                    <div className="w-1/3 md:w-full lg:px-10 2xl:px-16">
                        <Logo/>
                    </div>
                    <div className="w-full ">
                        <p className="font-body text-sm text-center">
                            &copy; 2024; Eden Green Technologies Pvt. Ltd.
                        </p>
                        <p className="font-body text-sm mt-1 text-center">
                            website Developed at <a href="https://theroguepixxel.com" target="_blank" rel="noreferrer" className="group text-green-500 hover:text-grey transition-colors ">the, rogue Pix<small className="text-grey group-hover:text-green-500 transition-colors">x</small>el</a>.
                        </p>
                    </div>
                </div>

                <div className="order-1 md:order-2 flex md:flex-col items-center space-x-5 md:space-x-0 md:space-y-5">
                    <h6 className="text-grey font-primary w-1/3 md:w-full lg:text-center text-sm md:text-lg">Services</h6>
                    <div className="w-full grid grid-cols-2 md:grid-cols-1 md:text-center gap-x-4 md:gap-x-0 gap-y-3 md:mx-0">
                        {/*<Link to="/buildings"*/}
                        {/*      className="px-0.5 font-body cursor-pointer font-medium tracking-widest transition-colors text-grey hover:text-dark text-sm md:text-base md:mx-3">Buildings</Link>*/}
                        {/*<Link to="/interior"*/}
                        {/*      className="px-0.5 font-body cursor-pointer font-medium tracking-widest transition-colors text-grey hover:text-dark text-sm md:text-base md:mx-3">Interiors</Link>*/}
                        <Link to="/landscape"
                              className="px-0.5 font-body cursor-pointer font-medium tracking-widest transition-colors text-grey hover:text-dark text-sm md:text-base md:mx-3">Landscape Engineering</Link>
                        <Link to="/water"
                              className="px-0.5 font-body cursor-pointer font-medium tracking-widest transition-colors text-grey hover:text-dark text-sm md:text-base md:mx-3">Water Engineering</Link>
                    </div>
                </div>
                <div className="order-2 md:order-3 flex md:flex-col items-center space-x-5 md:space-x-0 md:space-y-5">
                    <h6 className="text-grey font-primary w-1/3 md:w-full lg:text-center text-sm md:text-lg">About</h6>
                    <div className="w-full grid grid-cols-2 md:grid-cols-1 md:text-center gap-x-4 md:gap-x-0 gap-y-3 md:mx-0">
                        <HashLink smooth scroll={el => scrollWithOffset(el)} to="/about#"
                                  className="px-0.5 font-body cursor-pointer font-medium tracking-widest transition-colors text-grey hover:text-dark text-sm md:text-base md:mx-3">Company</HashLink>
                        <HashLink smooth scroll={el => scrollWithOffset(el)} to="/about#business"
                              className="px-0.5 font-body cursor-pointer font-medium tracking-widest transition-colors text-grey hover:text-dark text-sm md:text-base md:mx-3">Business</HashLink>
                        <HashLink smooth scroll={el => scrollWithOffset(el)} to="/about#vision"
                              className="px-0.5 font-body cursor-pointer font-medium tracking-widest transition-colors text-grey hover:text-dark text-sm md:text-base md:mx-3">Vision</HashLink>
                        <HashLink smooth scroll={el => scrollWithOffset(el)} to="/about#team"
                              className="px-0.5 font-body cursor-pointer font-medium tracking-widest transition-colors text-grey hover:text-dark text-sm md:text-base md:mx-3">Team</HashLink>
                    </div>
                </div>
                <div className="order-3 md:order-4 flex md:flex-col items-center space-x-5 md:space-x-0 md:space-y-5">
                    <h6 className="text-grey font-primary w-1/3 md:w-full lg:text-center text-sm md:text-lg">Products</h6>
                    <div className="w-full grid grid-cols-2 md:grid-cols-1 md:text-center gap-x-4 md:gap-x-0 gap-y-3 md:mx-0">
                        <HashLink smooth scroll={el => scrollWithOffset(el)} to="/services#"
                                  className="px-0.5 font-body cursor-pointer font-medium tracking-widest transition-colors text-grey hover:text-dark text-sm md:text-base md:mx-3">Products</HashLink>
                        <HashLink smooth scroll={el => scrollWithOffset(el)} to="/services#why"
                              className="px-0.5 font-body cursor-pointer font-medium tracking-widest transition-colors text-grey hover:text-dark text-sm md:text-base md:mx-3">Why Us?</HashLink>
                        <HashLink smooth scroll={el => scrollWithOffset(el)} to="/services#clients"
                              className="px-0.5 font-body cursor-pointer font-medium tracking-widest transition-colors text-grey hover:text-dark text-sm md:text-base md:mx-3">Clients</HashLink>
                    </div>
                </div>
                <div className="order-4 md:order-5 flex md:flex-col items-center space-x-5 md:space-x-0 md:space-y-5">
                    <h6 className="text-grey font-primary w-1/3 md:w-full lg:text-center text-sm md:text-lg">Get in Touch</h6>
                    <div className="w-full grid grid-cols-2 md:grid-cols-1 md:text-center gap-x-4 md:gap-x-0 gap-y-3 md:mx-0">
                        <p className="px-0.5 font-body font-medium tracking-wider text-grey text-xs md:text-base md:mx-3 ">Questions or feedback? <br/>We'd love to hear from you</p>
                        <div className=" text-grey flex space-x-5 order-5 items-center justify-around mt-2">
                            <div className="w-6">
                                <a href="https://www.facebook.com/www.edengreen.in/" target="_blank" rel="noreferrer"><FacebookIcon/></a>
                            </div>
                            <div className="w-6">
                                <a href="https://instagram.com/edengreentechnologies" target="_blank" rel="noreferrer"><InstagramIcon/></a>
                            </div>
                            <div className="w-6">
                                <a href="https://wa.me/919631968999" target="_blank" rel="noreferrer"><WhatsAppIcon/></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;