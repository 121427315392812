import React, {useRef} from 'react';
import {ReactComponent as Logo} from "../images/EdenGreenLogo_min.svg";
import {Link, NavLink} from "react-router-dom";
import {motion, useCycle} from "framer-motion";
import {MenuToggle} from "./MenuToggle";
import {useDimensions} from "../useDimensions";
import Headroom from "react-headroom";
import { HashLink } from 'react-router-hash-link';
import {scrollWithOffset} from './Footer'
const { innerWidth: width, innerHeight: height } = window;



const mobileNavs = {
    open: {transition: {staggerChildren: 0.07, delayChildren: 0.4}},
    closed: {transition: {staggerChildren: 0.05, staggerDirection: -1}},
}
const menuItems = {
    initial: {
        y: 100
    },
    open: {
        y: 0,
        opacity: 1,
        transition: {
            y: {stiffness: 1000, velocity: -100}
        }
    },
    closed: {
        y: 50,
        opacity: 0,
        transition: {
            y: {stiffness: 1000}
        }
    }
};
const sidebar = {
    open: (height = 1000) => ({
        // clipPath: `circle(${height * 2 + 200}px at 96.6% 1.35rem)`,
        y: 0,
        opacity: 1,
        transition: {
            type: "spring",
            stiffness: 400,
            damping: 40
        }
    }),
    closed: {
        // clipPath: "circle(60px at 96.6% 1.35rem)",
        y: -height,
        opacity: 0,
        transition: {
            delay: 0.2,
            type: "spring",
            stiffness: 400,
            damping: 40
        }
    }
};


const Navbar = (props) => {
    const [isOpen, toggleOpen] = useCycle(false, true);
    const containerRef = useRef(null);
    const {height} = useDimensions(containerRef);
    // const [isOpen, setIsOpen] = useState(false)



    // useEffect(() => {
    //     window.addEventListener("scroll", handleScroll);
    //     return () => window.removeEventListener("scroll", handleScroll);
    // }, []);


    return (
        <Headroom
            className={""} disableInlineStyles>

            <div className="flex items-center justify-between px-4 md:px-0">
                <div
                    className={"px-2 md:px-6 transform w-32 md:w-44 lg:w-52 transition-all scale-100"} id="logo">
                    <Link to="/">
                        <Logo/>
                    </Link>
                </div>
                <div className="flex-1 flex flex-col space-y-4">
                    <div
                        className={"hidden md:block w-full"}>
                        <div className="w-full flex items-center justify-end border-b border-transparent " id="topNavSection">
                            <Link to="/about"
                                  className="py-2 text-sd md:text-xs lg:text-sm mx-1 md:mx-1.5 lg:mx-2 px-0.5 text-sd font-body cursor-pointer font-medium tracking-widest transition-colors text-grey hover:text-dark">
                                About
                                Us</Link>
                            <HashLink to="/services#clients" smooth
                                      scroll={el => scrollWithOffset(el)}
                                  className="py-2 text-sd md:text-sm lg:text-sm mx-1 md:mx-1.5 lg:mx-2 px-0.5 text-sm font-body cursor-pointer font-medium tracking-widest transition-colors text-grey hover:text-dark">
                                Clients
                            </HashLink>
                            <HashLink smooth to="/services#"
                                      scroll={el => scrollWithOffset(el)}
                                  className="py-2 text-sd md:text-sm lg:text-sm mx-1 md:mx-1.5 lg:mx-2 px-0.5 text-sm font-body cursor-pointer font-medium tracking-widest transition-colors text-grey hover:text-dark">
                                Products
                                & Services</HashLink>
                            <Link to="/contact"
                                  className="py-2 text-sd md:text-sm lg:text-sm mx-1 md:mx-1.5 lg:mx-2 px-0.5 text-sm font-body cursor-pointer font-medium tracking-widest transition-colors text-grey hover:text-dark">
                                Contact
                                Us</Link>
                        </div>
                    </div>
                    <div
                        id="navs"
                        className="hidden md:flex flex-grow flex items-center justify-end">
                        {/*<NavLink to="/buildings"*/}
                        {/*         className="px-0.5 font-body filter drop-shadow cursor-pointer font-medium tracking-widest transition-colors text-grey hover:text-dark uppercase md:text-xl md:mx-3">Buildings</NavLink>*/}
                        {/*<NavLink to="/interior"*/}
                        {/*      className="px-0.5 font-body filter drop-shadow cursor-pointer font-medium tracking-widest transition-colors text-grey hover:text-dark uppercase md:text-xl md:mx-3">Interiors</NavLink>*/}
                        <NavLink to="/landscape"
                              className="px-0.5 font-body filter drop-shadow cursor-pointer font-medium tracking-widest transition-colors text-grey hover:text-dark uppercase md:text-xl md:mx-3">Landscape Engineering</NavLink>
                        <NavLink to="/water"
                              className="px-0.5 font-body filter drop-shadow cursor-pointer font-medium tracking-widest transition-colors text-grey hover:text-dark uppercase md:text-xl md:mx-3">Water Engineering</NavLink>
                    </div>
                    <motion.div className="md:hidden fixed top-0 left-0 bottom-0 right-0"
                                initial={false}
                                animate={isOpen ? "open" : "closed"}
                                custom={height}
                                ref={containerRef}>
                        <motion.div
                            className="background fixed top-0 left-0 right-0 h-screen bg-grey  "
                            variants={sidebar} style={{zIndex: 99998}}>
                            <motion.div className="w-full flex flex-col items-center justify-evenly h-full space-y-8"
                                        variants={mobileNavs}>
                                <motion.div
                                    variants={menuItems}
                                    className="px-2 md:px-6 transform w-52 transition-all relative">
                                    <Link to="/">
                                        <Logo/>
                                    </Link>
                                </motion.div>
                                {/*<motion.div variants={menuItems} className="w-full text-center relative">*/}
                                {/*    <Link to="/buildings"*/}
                                {/*          className="px-0.5 text-2xl font-primary cursor-pointer font-medium tracking-widest transition-colors text-white hover:text-dark uppercase md:mx-3">Buildings</Link>*/}
                                {/*</motion.div>*/}
                                {/*<motion.div variants={menuItems} className="w-full text-center relative">*/}
                                {/*    <Link to="/interior"*/}
                                {/*          className="px-0.5 text-2xl font-primary cursor-pointer font-medium tracking-widest transition-colors text-white hover:text-dark uppercase md:mx-3">Interiors</Link>*/}
                                {/*</motion.div>*/}

                                <motion.div variants={menuItems} className="w-full text-center relative">
                                    <Link to="/landscape"
                                          className="px-0.5 text-2xl font-primary cursor-pointer font-medium tracking-widest transition-colors text-white hover:text-dark uppercase md:mx-3">Landscape Engineering</Link>
                                </motion.div>
                                <motion.div variants={menuItems} className="w-full text-center relative">
                                    <Link to="/water"
                                          className="px-0.5 text-2xl font-primary cursor-pointer font-medium tracking-widest transition-colors text-white hover:text-dark uppercase md:mx-3">Water Engineering</Link>
                                </motion.div>
                                <div className="w-full flex items-center justify-evenly  pt-10">
                                    <motion.div variants={menuItems}>
                                        <Link to="/about"
                                              className="text-xs md:text-sm mx-1 md:mx-1.5 lg:mx-3 px-0.5 uppercase font-body cursor-pointer font-medium tracking-widest transition-colors text-white">
                                            About
                                            Us</Link>
                                    </motion.div>
                                    <motion.div variants={menuItems}>
                                        <Link to="/services#clients"
                                              className="text-xs md:text-sm mx-1 md:mx-1.5 lg:mx-3 px-0.5 uppercase font-body cursor-pointer font-medium tracking-widest transition-colors text-white">
                                            Clients
                                        </Link>
                                    </motion.div>
                                    <motion.div variants={menuItems}>
                                        <Link to="/services"
                                              className="text-xs md:text-sm mx-1 md:mx-1.5 lg:mx-3 px-0.5 uppercase font-body cursor-pointer font-medium tracking-widest transition-colors text-white">
                                            Products & Services
                                        </Link>
                                    </motion.div>
                                    <motion.div variants={menuItems}>
                                        <Link to="/contact"
                                              className="text-xs md:text-sm mx-1 md:mx-1.5 lg:mx-3 px-0.5 uppercase font-body cursor-pointer font-medium tracking-widest transition-colors text-white">
                                            Contact Us
                                        </Link>
                                    </motion.div>
                                </div>
                            </motion.div>

                        </motion.div>
                        <MenuToggle toggle={() => toggleOpen()}/>
                    </motion.div>
                </div>
            </div>
        </Headroom>
    )
}

export default Navbar;