import React, {lazy, Suspense} from 'react';
import './app.css';
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
import Navbar from "./components/Navbar";
import {AnimatePresence} from "framer-motion";
import Footer from "./components/Footer";
import {PuffLoader} from "react-spinners";
const Home = lazy(() => import('./Home'));
const About = lazy(() => import('./About'));
// const Buildings = lazy(() => import('./BuildingLanding.js'));
// const Interior = lazy(() => import('./InteriorLanding'));
const Water = lazy(() => import('./WaterLanding'));
const Landscape = lazy(() => import('./LandscapeLanding'));
const Contact = lazy(() => import('./Contact'));
const Services = lazy(() => import('./Services'));

const RouteWithNavbar = ({exact, path, component:Component, ...rest}) => {
    return <Route exact={exact} path={path} {...rest} render={(routeProps) => {
        return <><Navbar scrolled={true} {...routeProps}/><Component {...routeProps}/></>
            }}
            />
            }

const RouteWithCompressedNavbar = ({exact, path, component:Component, ...rest}) => {
    return <Route exact={exact} path={path} {...rest} render={(routeProps) => {
        return <><Navbar {...routeProps}/><Component {...routeProps}/></>
            }}
            />
            }


function App() {
    // const location = useLocation()
    return (
        <div className="min-h-screen font-body">
            <Router>
                <Route
                    render={({location}) => (
                        <>
                            <AnimatePresence exitBeforeEnter initial={false}>
                                <Suspense fallback={<div className="fixed w-full h-full bg-white inset-0 flex items-center justify-center" style={{zIndex:9999}}>
                                    <PuffLoader color="#29ce79"/>
                                </div>}>
                                <Switch location={location} key={location.pathname}>
                                    <Route path="/" exact component={Home}/>
                                    <RouteWithNavbar path="/about" component={About}/>
                                    <RouteWithNavbar path="/services" component={Services}/>
                                    {/*<RouteWithCompressedNavbar path="/buildings" component={Buildings}/>*/}
                                    {/*<RouteWithCompressedNavbar path="/interior" component={Interior}/>*/}
                                    <RouteWithCompressedNavbar path="/water" component={Water}/>
                                    <RouteWithCompressedNavbar path="/landscape" component={Landscape}/>
                                    <RouteWithNavbar path="/contact" component={Contact}/>
                                </Switch>
                                </Suspense>
                            </AnimatePresence>
                        </>
                    )}
                />
                <Footer/>



            </Router>
        </div>
    )
}

export default App;